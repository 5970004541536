import React, { useState, useEffect, useContext } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";

import BuyButton from "../components/BuyButton";
import Notification from "../components/Notification";
import SimilarProducts from "../components/SimilarProducts";
import ShippingInfo from "../components/ShippingInfo";
import SEO from "../components/SEO";

import CartContext from "../context/CartContext";
import { createSEOTags } from "../utils/helper";

import { Heading, Columns, Section, Dropdown, Table } from "react-bulma-components";

const ProductPage = ({ data, location }) => {
  const { airtable } = data;
  const product = airtable.data;

  const [selectedSize, setSelectedSize] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState();
  const [selectedProductImage, setSelectedProductImage] = useState(product.Images.localFiles[0]);
  const { addProduct } = useContext(CartContext);

  const metaTags = createSEOTags(product);

  product.Slug = airtable.fields.Slug;
  product.SelectedSize = selectedSize;

  useEffect(() => {
    if (product.Sizes && product.Sizes.length > 0) {
      setSelectedSize(product.Sizes[0]);
    }
  }, [product.Sizes]);

  const onSizeChange = (selected) => {
    setSelectedSize(selected);
  };

  const onBuyButtonClick = () => {
    const productText = `1 st ${product.Name}, ${product.SelectedSize} ${product.Measure} är tillagd i kundvagnen. `;
    addProduct(product);
    setNotificationText(productText);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
      setNotificationText("");
    }, 4000);
  };

  const canonicalUrl = `https://wallang.se${location.pathname}`;

  const jsonLd = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: product.Name,
    image: [`https://wallang.se${getSrc(product.Images.localFiles[0].childImageSharp.gatsbyImageData)}`],
    description: product.Description,
    offers: {
      "@type": "Offer",
      url: canonicalUrl,
      priceCurrency: "SEK",
      price: product.SalePrice || product.Price,
      itemCondition: "https://schema.org/NewCondition",
      availability: "https://schema.org/InStock",
    },
  };

  return (
    <>
      <Notification text={notificationText} show={showNotification} />
      <SEO
        title={metaTags.title}
        description={metaTags.description}
        image={product.Images.localFiles[0].childImageSharp.gatsbyImageData}
        schema={jsonLd}
        canonical={canonicalUrl}
      />
      <Section className="product-page-section">
        <Columns className="is-centered product-page">
          <Columns.Column className="product-page__img">
            <div className="product-image-wrapper">
              <div>
                <GatsbyImage alt="Product image main" image={selectedProductImage.childImageSharp.gatsbyImageData} />
              </div>
            </div>
          </Columns.Column>
          <Columns.Column>
            <Heading>{product.Name}</Heading>
            {!product.SalePrice && <span className="product-price">{product.Price} kr</span>}
            {product.SalePrice && (
              <p>
                <span className="product-old-price">{product.Price} kr</span> <span className="product-sale-price">{product.SalePrice} kr</span>
              </p>
            )}

            <BuyButton onClick={onBuyButtonClick} disabled={product.OutOfStock} />
            {product.OutOfStock && <span className="product-out-of-stock">Tillfälligt slut</span>}

            <div className="product-shipping-info">
              <ShippingInfo />
            </div>

            <section className="product-info">
              <div className="product-info__section">{product.Description}</div>
              {product.Material && (
                <div className="product-info__section">
                  <div>Material:</div>
                  <div>{product.Material.join(", ")}</div>
                </div>
              )}

              {product.Sizes && product.Sizes.length > 1 && (
                <div className="product-info__section dropdown-size">
                  <div>{product.MeasureTitle}:</div>
                  <Dropdown value={selectedSize} onChange={onSizeChange}>
                    {product.Sizes.map((value, index) => {
                      return (
                        <Dropdown.Item value={value} key={index}>
                          {`${value} ${product.Measure}`}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown>
                </div>
              )}

              {!product.Sizes && product.MeasureTitle && (
                <>
                  <div>{product.MeasureTitle}:</div>
                  <div>{product.Measure}</div>
                </>
              )}

              {product.Type === "Armband" && (
                <div className="product-info__section--col">
                  <h3 className="is-size-5 mb-2 py-2">Vilken storlek ska jag välja?</h3>
                  <p>Använd ett måttband och mät runt din handled. Lägg sedan på 2 centimeter. För tillfället finns våra armband i tre olika storlekar: 18, 20 och 21 cm.</p>
                </div>
              )}
            </section>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            {product.Images.localFiles.length > 2 && (
              <div className="product-images-display">
                {product.Images.localFiles.map((image, i) => {
                  return (
                    <div onClick={() => setSelectedProductImage(image)} className="product-image-display" key={i}>
                      <GatsbyImage alt={`Product image ${i + 1}`} image={image.childImageSharp.gatsbyImageData} />
                    </div>
                  );
                })}
              </div>
            )}

            {product.Images.localFiles.length === 2 && (
              <div className="product-images-display">
                <div className="product-image-display--single">
                  <GatsbyImage alt={`Product image`} image={product.Images.localFiles[1].childImageSharp.gatsbyImageData} />
                </div>
              </div>
            )}
          </Columns.Column>
        </Columns>
      </Section>
      <SimilarProducts productId={product.Id} productType={product.Type} />
    </>
  );
};

export const pageQuery = graphql`
  query ($id: Int!) {
    airtable(table: { eq: "Products" }, data: { Id: { eq: $id } }) {
      data {
        Id
        Images {
          localFiles {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            }
          }
        }
        Sizes
        Measure
        MeasureTitle
        Description
        Active
        Material
        Name
        Price
        SalePrice
        Type
        OutOfStock
      }
      fields {
        Slug
      }
    }
  }
`;

export default ProductPage;
