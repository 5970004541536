import React from 'react'
import { Link } from 'gatsby'

import './Notification.scss'

export default ({ text, show }) => (
  <div className={`notification-banner ${show ? 'show' : ''}`}>
    {text}
    <Link className="notification-banner__action-link" to="/checkout">
      Gå till kassan
    </Link>
  </div>
)
