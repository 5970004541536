import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import './Product.scss'

import { Card, Content } from 'react-bulma-components';

const Product = ({ product }) => {
  return (
    <Link to={product.Slug}>
      <Card className="product-link">
        <GatsbyImage alt="Product image" className="product-link-image" image={product.Images.localFiles[0].childImageSharp.gatsbyImageData} />

        <Card.Content>
          <Content>
            <h3 className="product-title">{product.Name}</h3>
            {!product.SalePrice && <p className="product-price">{product.Price} kr</p>}
            {product.SalePrice && (
              <p>
                <span className="product-old-price">{product.Price} kr </span> <span className="product-sale-price">{product.SalePrice} kr</span>
              </p>
            )}
          </Content>
        </Card.Content>
      </Card>
    </Link>
  )
}

export default Product
