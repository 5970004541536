import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { sampleSize } from 'lodash'
import ProductList from '../ProductList'

const SimilarProducts = React.memo(({ productId, productType }) => {
  const data = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Products" }, data: { Active: { eq: true } } }) {
        edges {
          node {
            data {
              Id
              Images {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              Active
              Name
              Price
              SalePrice
              Type
            }
            fields {
              Slug
            }
          }
        }
      }
    }
  `)

  const otherProducts = data.allAirtable.edges.filter(p => p.node.data.Id !== productId && p.node.data.Type === productType)
  const products = sampleSize(otherProducts, 4).map(({ node }) => ({ ...node.data, ...node.fields }))

  if(products.length > 0) {
    return <ProductList title="Liknande produkter" products={products} />
  }

  return <div style={{ height: '10px' }}></div>
})

export default SimilarProducts
