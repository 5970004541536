const slugify = (value) => {
  return value
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-and-") // Replace & with ‘and’
    .replace(/å/g, "a") // Replace å with a
    .replace(/ä/g, "a") // Replace ä with a
    .replace(/ö/g, "o") // Replace ö with o
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

const calculateOrderAmount = (products) => products.reduce((total, p) => (total += p.Price * 100 * p.Qty), 0);
const calculateTaxAmount = (value) => Math.round(value - (value * 10000) / (10000 + 2500)); // formulea from Klarna

const createOrderItems = (products) => {
  const taxRate = 2500;
  return products.map((p) => {
    const totalAmount = p.Price * 100 * p.Qty;
    return {
      type: "physical",
      reference: p.Id,
      name: `${p.Name} ${p.SelectedSize} ${p.Measure || ""}`,
      quantity: p.Qty,
      unit_price: p.Price * 100,
      tax_rate: taxRate,
      total_amount: totalAmount,
      total_tax_amount: Math.round(totalAmount - (totalAmount * 10000) / (10000 + taxRate)), // formula from Klarna
    };
  });
};

const createSEOTags = (product) => {
  let title, description;

  console.log(product);

  switch (product.Type.toLowerCase()) {
    case "armband":
      title = `Modernt läderarmband med spänne i rostfritt stål | ${product.Name}`;
      description = `Fri frakt och snabb leverans. ${product.Name} läderarmband för ${product.SalePrice || product.Price} kr.`;
      break;
    case "skarp":
      title = `Modernt skärp i läder | ${product.Name}`;
      description = `Fri frakt och snabb leverans. ${product.Name} skärp för ${product.SalePrice || product.Price} kr.`;
      break;
    case "prylar":
      title = `${product.Name}`;
      description = `${product.Name}, ${product.SalePrice || product.Price} kr. Fri frakt och snabb leverans.`;
      break;
    default:
      break;
  }

  return {
    title,
    description,
  };
};

module.exports = {
  slugify,
  calculateOrderAmount,
  calculateTaxAmount,
  createOrderItems,
  createSEOTags,
};
