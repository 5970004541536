import React from 'react'
import { Button } from 'react-bulma-components';

import './BuyButton.scss'

export default ({ onClick, disabled }) => {
  return (
    <Button disabled={disabled} className="buy-button wallang-btn" onClick={() => onClick()}>
      Lägg i kundvagn
    </Button>
  )
}
