import React from 'react'
import { Columns, Container, Section, Heading } from 'react-bulma-components';

import './ProductList.scss'

import Product from '../Product'

const ProductList = ({ products, title, scrollId }) => {
  return (
    <Container id={scrollId}>
      {title && (
        <div className="product-list-heading-wrapper">
          <Heading renderAs="h2" className="product-list-heading">
            {title}
          </Heading>
        </div>
      )}
      <Section className="product-list">
        <Columns breakpoint="mobile">
          {products.map(product => (
            <Columns.Column
              key={product.Id}
              mobile={{
                size: 'half'
              }}
              tablet={{
                size: 'one-quarter'
              }}
            >
              <div className="product-wrapper">
                <Product product={product} />
              </div>
            </Columns.Column>
          ))}
        </Columns>
      </Section>
    </Container>
  )
}

export default ProductList
